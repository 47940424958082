@import 'properties';

#content .gallery {
    clear: both;
    margin: 25px;

    .image {
        float: left;
        margin: 0 0 40px 10px;


        &.first-in-row {
            clear: left;
            margin: 0 0 10px 0;
        }
        .gallerytext {
            margin-top: 10px;
            color: #000;
            position: absolute;
            width: 220px;
        }
        .imgWrap {
            max-height: 143px;
            max-width: 220px;
            border-radius: 4px;
            box-shadow: (rgba(black, 0.3) 0 2px 3px 1px);
            overflow: hidden;
        }
    }

    h2,
    p {
        text-align: center;
        width: 375px;

    }
    h2 {
        margin: 0 auto 30px;

    }
    p {
        margin: 0 auto 50px;
    }
}
