@import 'reset';
@import "../../lib/fancybox";
@import "../../lib/jquery-ui";
@import 'fontAwesome';
@import 'properties';
@import 'gallery';
@import 'slideshow';

html, body {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}

body {
    font-size: $fontSize;
    line-height: $lineHeight;
    color: $fontColor;
    font-family: $fontPrimary;
    background: $background; 
    background-size: cover;
}
h1,
h2,
h3 {
    font-weight: bold;
    color: $fontColor;
}
h1 {
    display: block;
    font-size: 25px;
    -webkit-margin-before: 0.67em;
    -webkit-margin-after: 0.67em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;

}
h2 {
    font-size: 25px;
}
h3 {
    font-size: 20px;
}
p {
    font-size: 12px;

}
#fancybox-title {
    display: none;
}
.marginTop{
    margin-top: 30px !important;
}
.redButton {
    @include transition(all 0.3s);
    box-shadow: inset 0 1px 1px rgba(255,255,255,.75); // inner shadow
    background: -webkit-linear-gradient(#ff0003 , #d60003); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(#ff0003 , #d60003); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(#ff0003 , #d60003); /* For Firefox 3.6 to 15 */
    background: linear-gradient(#ff0003 , #d60003); /* Standard syntax */
    height: 38px;
    line-height: 38px;
    padding: 9px 30px;
    color: #fff !important;
    font-size: 14px;
    border: 1px solid #e50000; // stroke
    background-color: #f00; // layer fill content
    font-family: "OpenSans-Extrabold", sans-serif;
    font-weight: bold;
    text-shadow:0 1px 1px rgba(0,0,0,.4); // drop shadow
    -webkit-font-smoothing: antialiased;

    &:hover {
        border: 1px solid #e50000; // stroke
        background: #ce0004;
        text-shadow: none;

    }
}
.blueButton {
    @include transition(all 0.0s);
    font-family: "OpenSans-Extrabold", sans-serif;
    height: 38px;
    line-height: 38px;
    padding: 0 17px;
    color: #fff !important;
    text-shadow:0 1px 1px rgba(0,0,0,.4); // drop shadow
    font-size: 14px;
    font-weight: bold;
    border: 1px solid #005197;
    display: block;
    width: 60px;
    margin-top: 40px;
    box-shadow:inset 0 1px 2px rgba(255,255,255,.32); // inner shadow
    background: -webkit-linear-gradient(#005dad , #004f92); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(#005dad , #004f92); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(#005dad , #004f92); /* For Firefox 3.6 to 15 */
    background: linear-gradient(#005dad , #004f92); /* Standard syntax */
    -webkit-font-smoothing: antialiased;


    &:hover {
        border: 1px solid #003f74; // stroke
        box-shadow: none;
        background: #004989;;
    }
}
#page {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin-bottom: $footerHeight * -1;
}

#push {
    height: $footerHeight;
}

a {
    @include transition(all 0.3s);
    color: inherit;
    text-decoration: inherit;
}

input[type=text],
input[type=date],
input[type=number],
input[type=password],
textarea {
    @include border-box();
    @include transition(all 0.3s);
    height: $inputHeight;
    border: 1px solid #ccc;
    padding: 10px;
    outline: 0;
}

textarea {
    height: 130px;
}

.button,
a.button,
input[type=submit],
button {
    @include transition(all 0.3s);
    @include border-box();
    @include appearance(none);
    border-radius: $borderRadius;
    height: $buttonHeight;
    border: none;
    background: $colorPrimary;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 400;
    font-family: $fontPrimary;
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
    text-align: center;
    &:hover {
        opacity: 1;
        background: $colorSecondary;
    }
}

a.button {
    line-height: $buttonHeight;
}

.select {
    @include border-box();
    border-radius: $borderRadius;
    position: relative;
    width: 118px;
    border: 1px solid #ddd;
    height: $inputHeight;
    line-height: $inputHeight;
    overflow: hidden;
    &:after {
        display: block;
        position: absolute;
        top: 0;
        right: 12px;
        font-family: FontAwesome;
        font-size: 12px;
        content: '\f0d7';
        color: #706b69;
        line-height: inherit;
        z-index: 0;
    }
 
    select {
        @include resetSelect();
        position: relative;
        height: 100%;
        padding-left: 10px;
        z-index: 1;
        color: #706b69;
    }
}

.fa {
    line-height: inherit;
}

#sb-site {
    height: 100%;
}

.wrap {
    @include center();
    @include cf();
    padding: 0 55px;
}

.clear {
    clear: both;
}

.cf {
    @include cf();
}

.left,
.fl {
    float: left;
}

.right,
.flr {
    float: right;
}

.por,
.relative {
    position: relative;
}



.breadcrumb {
    $height: 70px;
    @include cf();
    li {
        height: $height;
        line-height: $height;
        float: left;
        font-size: 12px; 
        a {
            &:hover {
                color: $colorPrimary;
            }
        }

        .fa {
            padding: 0 3px 0 4px;
            font-size: 14px;
        }
    }
}
.header {
    background: $firstColor;
    color: $secondcolor;
    text-align: center;
    img {
        margin: 20px;
    }

    .navigation {
        width: 100%;
        border-top: 1px solid #005093;
        height: 60px;
        background: $firstColor;

        > li {
            position: relative;
            > ul {
                position: absolute;
                left: 0;
                display: none;
                a {
                    white-space: nowrap;
                }
            }
            &:hover > ul {
                display: block;
            }
        }
        li {
            z-index: 9000;
            display: inline-block;
            &.active,
            &:hover {
                @include transition(all 0.3s);
                a, i {
                    background: $activecolor;

                }
            }
            a {

            }
            a {

            border-radius: 3px;
            font-size: 14px;
            padding: 8px 13px;
            font-weight: 700;
            line-height: 60px;

                &:hover {
                    background: $hovercolor;
                }
                &:active {
                    background: $activecolor;
                }
            }
            ul {
                top: 51px;
                padding: 5px 5px 10px 5px;
                border-radius: $borderRadius;
                background-color: #003e72;
                text-align: left;
                li {
                    a {
                        font-weight: 200;
                        font-size: 13px;
                        line-height: 25px;
                        &:hover {
                            background: transparent;
                            text-decoration: underline;

                        }
                    }
                }
            }
        }
    }
}

body.pageStart #content > .wrap {
    padding: 0 0 0px 0;
}

#content {
    margin-bottom: 140px;
    .family {
        height: 65px;
        width: 100%;
        text-align: center;
        color: $familycolor;
        font-size: 17px;
        line-height: 65px;
        p {
            color: rgba(193,166,135, 0.8);
            text-shadow: 1px 0px 3px #FFF, 0 0 0 #000, 0 0 0px #fff;
            font-size: 18px;
            font-weight: bolder;
        }
    }
    >.wrap {
        @include border-box();
        border-radius:3px 2px; // border radius
        background-clip:padding-box; // prevents bg color from leaking outside the border
        box-shadow:0 2px 3px rgba(0,0,0,.3); // drop shadow

        background-color: $secondcolor;
        position: relative;
        min-height: 400px;
        font-size: 13px;
        padding: 60px 20px 70px 20px;
        > h1,
        h2,
        h3 {
            margin-bottom:30px;
            padding: 0 30px;
            margin-top: 0;
        }

        > p {
            padding: 0 30px;
        }
        > .tinyInline {
            > h1,
            h2,
            h3 {
                margin-bottom:30px;
                padding: 0 30px;
                margin-top: 0;
            }
            > p {
                padding: 0 30px;
            }
        }
        .slideshow {
            margin-bottom: 0;
            img {
                margin-left: 0;
                margin-right: 0;
            }
            &:hover {
                .change-slider {
                    opacity: 1;
                }
            }
            .change-slider {
                @include transition(all 0.3s);
                border: 1px solid #005197; // stroke
                background-color: #005dad; // layer fill content
                box-shadow:inset 0 1px 1px rgba(255,255,255,.32); // inner shadow
                background-image:linear-gradient(top, #005dad 0%, #004f92 100%); // color overlay + gradient overlay
                border-radius: 0;
                width: 38px;
                height: 38px;
                opacity: 0;
                line-height: 38px;
                color: $secondcolor;
                text-shadow: 0px 1px 2px #111;
                position: absolute;
                i {
                    font-size: 17px;
                    top: -2px;
                    position: relative;
                }
                &:hover {
                    background: #004a89;
                    box-shadow: inset 0 1px 1px #004a89;

                }
                &.cycle-next {
                    right: 0;
                }
                &.cycle-prev {
                    left: 0;
                }
            }
        }
        .leftShadow {
            width: 52%;
            height: 100%;
            opacity:.05; // layer alpha
        }
            .welcome_text {
                width: 50%;
                padding-bottom: 120px;
                float: left;
                padding-top: 48px;
                background-image: $avdelare2;
                background-position: right;
                background-repeat: no-repeat;
                background-size: 4%;
                p {
                    font-size: 13px;
                    margin-right: 56px;
                }
                > h1 {
                    margin-bottom: 30px;
                }
            }

        .contentRight {
            float: right;
            margin: 0 27px;
            width: 40%;
            padding-top: 48px;
            padding-bottom: 20px;
            h1,
            h2,
            h3 {
                margin: 0 50px 40px 0;
                padding-top: 19px;
                padding-left: 0 ;
            }
            img,
            p {
                margin-left: 0;
                margin-right: 0;
                float: left;
            }
            p {
                line-height: 60px;
                padding-left: 10px;

            }
            img {
                margin: 0 0 10px 0;
            }

            table, tbody, tr, td {
                display: block;
                width: 100%;
            }

            tr {
                @include cf();
                td {
                    width: auto;
                    padding: 0;
                    float: left;
                    &:first-child {
                        width: 200px;
                    }
                }
            }

            table {
                width: 385px;

                td {
                    position: relative;
                    z-index: 500;
                    a {
                        font-weight: 700;
                        color: #006ac4;
                        &:hover {
                            color: #004a89;
                            @include transition(all 0.3s);
                        }
                    }
                    p {
                        font-weight: 700;
                        color: #006ac4;
                    }
                    a:hover {
                        .serviceImg {
                            background: #005196;
                        }
                    }
                    .serviceImg {
                        @include transition(all 0.3s);

                        box-shadow: inset 0 1px 0 rgba(255,255,255,.32); // inner shadow
                        background: -webkit-linear-gradient(#0069c4,#0069c4) #005dac;
                        background: linear-gradient(#0069c4,#0069c4) #005dac;
                        border-radius: 35px;
                        width: 57px;
                        height: 57px;
                        float: left;
                        margin: 0 0 15px 0;
                        background-repeat: no-repeat;
                        border: 1px solid rgba(0,52,96,.6); // stroke
                        + span.text {
                            float: left;
                            line-height: 60px;
                            margin: 0 0 0 10px;
                        }
                    }


                    .nailImg img {
                        left: 21px;
                        top: 17px;
                        position: relative;
                    }
                    .toolImg img {
                        left: 14px;
                        top: 14px;
                        position: relative;
                    }
                    .shovelImg img {
                        top: 9px;
                        position: relative;
                    }
                    .bricksImg img {
                        top: 15px;
                        position: relative;
                    }
                    .sawImg img {
                        left: 10px;
                        top: 11px;
                        position: relative;
                    }
                }
                .tdLeft {
                    //padding-right: 35px;
                }
            }
        }
        .clear {
            @include cf();
        }
        .contactPage {
            padding: 0 0 40px 0;
                .leftContent {
                    width: 50%;
                    float: left;
                    background-image: $avdelare;
                    background-position: right;
                    background-repeat: no-repeat;
                    background-size: 14px;
                    padding-bottom: 63px;

                    h1, h2, h3 {
                        padding: 0;
                        margin-top: 0;
                        margin-left: 33px;
                    }
                    p {
                        margin-bottom: 8px;
                        margin: 0 36px 0 34px;
                    }
                    table {
                        margin: 0 50px 0 63px;
                        td {
                            padding: 5px 12px 35px 5px;
                            position: relative;
                            img {
                                position: absolute;
                                top: -1px;
                                left: -28px;
                            }
                        }
                        p {
                            margin: 0 0 4px 0;
                        }
                    }
                }
                .rightContent {
                    padding-top: 20px;
                    position: relative;
                    padding-bottom: 50px;
                    right: 50px;
                    width: 45%;
                    float: right;
                    label {
                        margin-right: 10px;
                        line-height: 34px;
                    }
                    input, textarea {
                        box-shadow: (rgba(gray, 0.2) 0 0 3px 1px inset);
                        width: 280px;
                        float: right;
                        box-sizing: border-box;

                        height: 33px;
                        background: #fafafa;
                        margin-bottom: 10px;
                        font-family: 'Open Sans', sans-serif;
                        padding: 0 10px;
                        font-size: 15px;
                        outline: 0;
                        border: 1px solid #ccc;
                    }
                    textarea {
                        padding: 10px;
                        height: 184px;
                    }
                    p {
                        margin: 0 10px 0 0;
                        float: right;
                    }
                    button {
                        position: absolute;
                        bottom: 0;
                        right: 10px;
                        @include transition(all 0.0s);
                        height: 38px;
                        width: 280px;
                        color: #fff !important;
                        font-family: "OpenSans-Extrabold", sans-serif;
                        font-size: 14px;
                        font-weight: bold;
                        text-transform: none;
                        text-shadow: 0 1px 1px rgba(0,0,0,.4);
                        border-radius: 0;
                        border: 1px solid #e50000; // stroke
                        box-shadow:inset 0 1px 1px rgba(255,255,255,.75); // inner shadow
                        background: -webkit-linear-gradient(#ff0003 , #d60003); /* For Safari 5.1 to 6.0 */
                        background: -o-linear-gradient(#ff0003 , #d60003); /* For Opera 11.1 to 12.0 */
                        background: -moz-linear-gradient(#ff0003 , #d60003); /* For Firefox 3.6 to 15 */
                        background: linear-gradient(#ff0003 , #d60003); /* Standard syntax */
                        -webkit-font-smoothing: antialiased;
                        &:hover {
                            background: #ce0005;
                        }
                    }
                }

        }
        .gallery {
            margin: 0;
            .image {
                max-width: 220px;
                max-height: 206px;
                height: 206px;
            }
        }
        .gallerytext {
            color: #595959 !important;
            p {
                color: #595959;
            }
        }

    }
    .guy {
        width: 140px;
        height: auto;
        position: absolute;
        bottom: -88px;
        right: -43px;
    }

}

.welcome_text {
    width: 50%;
    height: auto;
}

.footer {
    background: $firstColor;
    color: $secondcolor;
    height: auto;

    .certificate {
        width: 100%;
        background: $secondcolor;
        box-shadow: (rgba(gray, 0.2) 0 2px 3px 1px inset);
        .center {
            text-align: center;
            img {
                max-height: 95px;
                width: auto;
                margin: 20px 17px;
            }
        }
    }
    .footerLine {
        width: 100%;
        .wrap {
            max-width: $pageWidth;
            width: 93%;
            margin: auto;
            p, li{
                font-size: 12px;
                font-weight: 200;
            }
            img {
                margin-top: 40px;
            }
            h2 {
                color: #fff;
                margin-bottom: 25px;
                font-size: 22px;
            }
            .contactInfo {
                margin-top: 48px;
                margin-bottom: 50px;
                width: 220px;
                float: right;
                padding-right: 40px;
                li {
                    font-size: 12px;
                }
            }
            .footerRight {
                margin-top: 48px;
                margin-bottom: 40px;
                height: 100%;
                width: 220px;
                float: right;
            }
        }
    }
    .signatur {
        box-shadow: inset 0 1px 2px rgba(255,255,255,.32); // inner shadow
        background-image: linear-gradient(top, rgba(0,94,173,.84) 0%, rgba(0,75,139,.84) 100%); // color overlay + gradient overlay
        border: 1px solid #005197; // stroke
        background-color: #005dad; // layer fill content
        width: 100%;
        text-align: center;
        font-weight: 100;
        font-size: 12px;
        a {
            line-height: 60px;
            &:hover {
                text-decoration: underline;
            }
        }
    }

}

#fancybox-title-wrap{
    display: none !important;
}


.post_page {
    padding: 30px 0;
    .posts {
        margin: 0 0 30px 0;
        padding: 15px;
        width: 700px;
        border-bottom: 1px solid #ccc;
        border-left: 1px solid #ccc;
    }
}

.alert {
    border-radius: $borderRadius;
    margin-bottom: 18px;
    padding: 8px 8px 8px 8px;
    border: 1px solid #fbeed5;
    background-color: #fcf8e3;
    color: #c09853;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    .close {
        position: relative;
        top: -2px;
        right: -21px;
        float: right;
        line-height: 18px;
    }
}

.alert-success {
    border-color: #d6e9c6;
    background-color: #dff0d8;
    color: #468847;
}

.alert-danger, .alert-error {
    border-color: #eed3d7;
    background-color: #f2dede;
    color: #b94a48;
    button {
        position:inherit !important;
        width: auto !important;
    }
    ul {
        margin: 27px 0;
    }
    p {
        float: none !important;
    }
}

.alert-info {
    border-color: #bce8f1;
    background-color: #d9edf7;
    color: #3a87ad;
}
