@import 'properties';
.slideshow {
    position: relative;
    width: 100%;
    height: 417px;
    overflow: hidden;
    img {
        width: 100%;
        height: auto;
    }
    .change-slider {
        border-radius: 25px;
        position: absolute;
        top: 50%;
        z-index: 140;
        width: 25px;
        height: 25px;
        border: 1px solid #1e1e1e;
        background: #fff;
        color: #1e1e1e;
        text-align: center;
        text-decoration: none;
        font-size: 22px;
        line-height: 24px;
        &.cycle-prev {
            left: 10px;
        }
        &.cycle-next {
            right: 10px;
        }
    }
    .cycle-pager {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 140;
        width: 100%;
        height: 30px;
        text-align: center;
        line-height: 30px;
        span {
            border-radius: 100%;
            display: inline-block;
            margin: 0 3px;
            width: 20px;
            height: 20px;
            border: 1px solid #000;
            background: #fff;
            color: transparent;
            line-height: 20px;
        }
        .cycle-pager-active {
            background: #1e1e1e;
        }
    }
}
